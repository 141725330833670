<template>
  <div>
    <!-- title 组件 -->
    <div class="content-tile">
      <div class="su-title">
        <i class="el-icon-document-copy"></i>
        <span>物资管理</span>
      </div>
      <div>
        <!--横等于20000显示-->
        <el-button
            type="primary"
            @click="addLog"
        >
          物资录入
        </el-button>
      </div>
    </div>
<!--    <common-tabs-->
<!--        :tabsProp="tabsProp"-->
<!--        @tabsClick="tabsClick"-->
<!--        @keyWordMethod="keyWordMethod"-->
<!--        @highKeyWordMethod="highKeyWordMethod"-->

<!--    />-->
    <!--table 组件 -->
    <el-table
        :data="tableData"
        border
        style="width: 100%">
      <el-table-column type="expand">
        <template slot-scope="scope">
          <el-table
              :data="scope.row.details"
              border
              style="margin: 5px; width: 99%">
            <el-table-column
                align="center"
                label="采购日期">
              <template slot-scope="scope">
                {{ Utils.timeDate(scope.row.purchase_time) }}
              </template>
            </el-table-column>
            <el-table-column
                prop="unit_price"
                align="center"
                label="单价">
            </el-table-column>
            <el-table-column
                prop="num"
                align="center"
                label="数量">
            </el-table-column>
            <el-table-column
                prop="stock"
                align="center"
                label="剩余">
            </el-table-column>
            <el-table-column
                align="center"
                label="金额">
              <template slot-scope="scope">
                {{ scope.row.unit_price*scope.row.num }}
              </template>
            </el-table-column>
            <el-table-column
                prop="address"
                align="center"
                label="录入时间">
              <template slot-scope="scope">
                {{ Utils.timeDate(scope.row.updated_at) }}
              </template>
            </el-table-column>
            <el-table-column
                prop="address"
                align="center"
                label="操作">
              <template slot-scope="scope">
                <div class="table-button">
                  <ul>
                    <li><el-button type="primary" @click="editMaterialDetail(scope.row)">编辑</el-button></li>
                    <li><el-button type="danger" @click="delMaterialDetail(scope.row)">删除</el-button></li>
                  </ul>
                </div>
              </template>
            </el-table-column>
          </el-table>
        </template>
      </el-table-column>
      <el-table-column
          label="物资名称"
          align="center"
          prop="name">
      </el-table-column>
      <el-table-column
          label="条形码"
          align="center"
          prop="code">
      </el-table-column>
      <el-table-column
          label="单位"
          align="center"
          prop="unit">
      </el-table-column>
      <el-table-column
          label="总数量"
          align="center"
          prop="number">
      </el-table-column>
      <el-table-column
          label="总金额"
          align="center"
          prop="total_price">
      </el-table-column>
      <el-table-column
          label="已使用"
          align="center"
          prop="used">
      </el-table-column>
      <el-table-column
          label="剩余"
          align="center"
          prop="stock">
      </el-table-column>
      <el-table-column
          label="创建时间"
          align="center">
        <template slot-scope="scope">
          {{ Utils.timeDate(scope.row.updated_at) }}
        </template>
      </el-table-column>
      <el-table-column
          label="操作"
          align="center"
          width="250px"
          prop="desc">
        <template slot-scope="scope">
          <div class="table-button">
            <ul>
              <li><el-button type="primary" @click="editMaterial(scope.row)">编辑</el-button></li>
              <li><el-button type="primary" @click="addMaterialDetail(scope.row)">添加明细</el-button></li>
              <li><el-button type="danger" @click="deleteMaterial(scope.row)">删除</el-button></li>
            </ul>
          </div>
        </template>
      </el-table-column>
    </el-table>
<!--    分页-->
    <div class="pagination">
      <el-pagination
          background
          @size-change="handleSizeChange"
          @current-change="pageCurrentChange"
          :current-page="page.current_page"
          :page-size="page.size"
          :page-sizes="[10, 20, 30, 40,50]"
          :layout="page.type == 2?'total, sizes, prev, pager, next': 'total, prev, pager, next'"
          :total="page.total">
      </el-pagination>
    </div>
    <!--addDialog 组件 -->
    <common-add-dialog
        :addDialogData="addDialogData"
        @addDialogSub="addDialogSub"
    />
    <!--addDialog 组件 -->
    <common-add-dialog
        :addDialogData="addDetailData"
        @addDetailSub="addDetailSub"
    />
  </div>
</template>
<script>
// import {timer} from '@/assets/js/comment'
import {addMaterialDetail, delMaterialDetail, editMaterialDetail, getMaterialList} from '@/api/material/api';
import {deleteMaterial, editMaterial, putMaterial} from "../../../api/material/api";

export default {
  components: {},
  props: [],
  data() {
    return {
      //title 数据源
      titleProp: {
        name: '物资管理',//标题名称
        butOperate: [{
          name: '添加物资',
          type: 'primary',
          method: 'addLog',
          id: 2000,
        }]
      },
      page: {
        type: 2,
        current_page: 1,
        total: 0,
        size: 10,
        method: 'pageCurrentChange',
      },
      //addDialog数据源
      addDialogData: {
        type: 1,   //1-增 2-改
        isDialog: false,//dialog是否显示
        subMethod: 'addDialogSub',//点击确定方法名
        title: '123',// dialog 标题
        top: '50px', // dialog 离浏览器top
        width: '900px', // dialog 长度，可以是百分比
        labelWidth: '160px', // label 长度
        //dialog 数据源
        addDialogProp: [{
          label: '物资名称',
          field: 'name',
          type: 2,
          show: true,
          required: true,
        }, {
          label: '条码',
          field: 'code',
          type: 2,
          show: true,
        }, {
          label: '单位',
          field: 'unit',
          type: 2,
          show: true,
          required: true,
        }],
        //dialog 显示数据
        addDialogShowData: {
          name:'',
          code:'',
          unit:'',
        },
        // 下拉框的 options和单选框，多选框
      },
      //addDialog数据源
      addDetailData: {
        type: 1,   //1-增 2-改
        isDialog: false,//dialog是否显示
        subMethod: 'addDetailSub',//点击确定方法名
        title: '添加物资明细',// dialog 标题
        top: '50px', // dialog 离浏览器top
        width: '900px', // dialog 长度，可以是百分比
        labelWidth: '160px', // label 长度
        //dialog 数据源
        addDialogProp: [{
          label: '物资名称',
          field: 'name',
          type: 1,
          show: true,
        }, {
          label: '采购日期',
          field: 'purchase_time',
          type: 10,
          show: true,
          required: true,
        }, {
          label: '单价',
          field: 'unit_price',
          type: 2,
          show: true,
          required: true,
        }, {
          label: '数量',
          field: 'num',
          type: 2,
          show: true,
          required: true,
        }],
        //dialog 显示数据
        addDialogShowData: {
          name:'',
          material_id: '',
          purchase_time:'',
          unit_price:'',
          num:'',
        },
        // 下拉框的 options和单选框，多选框
      },

      //tabs 数据源
      tabsProp: {
        active: '0',
        method: 'tabsClick', //点击tabs方法名
        keywords: '',//搜索的值
        keyWordMethod: 'keyWordMethod',// 搜索方法名
        keywordsShow: false,//搜索框是否显示
        isHighKeyWord: false,//是否有高级搜索
        highKeyWordDialogMethod: 'highKeyWordMethod',//高级搜索点击确定*/
        //高级搜索循环体
        highKeyWord: [{
          name: '时间范围',
          field: 'status',
          type: 5,
        }],
        //高级循环，下拉框等 内容名称 字段名+Options  接口读取

        //高级搜索的字段
        heighKewWordField: {status: ''},
        //tabs 标题
        tabsData: [{
          label: '全部',
          name: '0',
          total: 0,
        }]
      },
      //表格数据
      tableData: [],
    };
  },
  created() {
    this.initLoad();
  },
  mounted() {
  },
  methods: {
    addLog(){
      this.addDialogData.addDialogShowData = this.$options.data().addDialogData.addDialogShowData;
      this.addDialogData.type = 1
      this.addDialogData.title = '物资录入';
      this.addDialogData.isDialog = true;
    },
    editMaterial(row){
      this.addDialogData.addDialogShowData = this.$options.data().addDialogData.addDialogShowData;
      this.addDialogData.addDialogShowData.name = row.name
      this.addDialogData.addDialogShowData.code = row.code
      this.addDialogData.addDialogShowData.unit = row.unit
      this.addDialogData.addDialogShowData.id = row.id
      this.addDialogData.type = 2
      this.addDialogData.title = '编辑物资';
      this.addDialogData.isDialog = true;
    },
    deleteMaterial(row){
      this.$confirm('确定要删除吗?', '信息', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        if(row.used==0){
          deleteMaterial({id:row.id}).then((result) => {
            this.addDialogData.isDialog = false;
            this.$message({
              message: result.msg,
              type: 'success'
            });
            this.initLoad()

          }).catch((error) => {
            this.$message({
              message: error.msg,
              type: 'error'
            });
          })
        }else {
          this.$message({
            message: "已使用，不可删除",
            type: 'error'
          });
        }

      })
    },
    // addDialog 点击确定
    addDialogSub() {
      let subData = {...this.addDialogData.addDialogShowData}  //ES6深拷贝
      //必填信息判断
      if (!subData.name || !subData.unit ){
        this.$confirm('*为必填项信息，请填写完整后提交！', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {})
        return false
      }

      console.log(subData)
      if (this.addDialogData.type == 2){
        editMaterial(subData).then((result) => {
          this.addDialogData.isDialog = false;
          this.$message({
            message: result.msg,
            type: 'success'
          });
          this.initLoad()
        })
      }else {
        putMaterial(subData).then((result) => {
          this.addDialogData.isDialog = false;
          this.$message({
            message: result.msg,
            type: 'success'
          });
          this.initLoad()
        })
      }

    },
    //表格初始化
    initLoad() {
      this.$store.state.loading = true;
      let params = {
        page: this.page.current_page,
        per_page: this.page.size,
      }

      if(this.tabsProp.heighKewWordField.status){
        params.start_time = this.tabsProp.heighKewWordField.status[0] / 1000
        params.end_time = this.tabsProp.heighKewWordField.status[1] / 1000
      }

      getMaterialList(params).then((result) => {
        console.log(result)
        let res = result.data;

        this.page.current_page = res.current_page;
        this.page.total = res.total;
        this.page.size = Number(res.per_page);
        this.tableData = res.data
        for (let i = 0; i < this.tableData.length; i++) {
            this.tableData[i].purchase_date = res.data[i].purchase_date/1000
        }
      })
    },
    //添加物资明细
    addMaterialDetail(row){
      this.addDetailData.addDialogShowData = this.$options.data().addDetailData.addDialogShowData
      this.addDetailData.title = '添加物资明细'
      this.addDetailData.type = 1
      this.addDetailData.addDialogShowData.name = row.name
      this.addDetailData.addDialogShowData.material_id = row.id
      this.addDetailData.isDialog = true
    },
    //修改物资明细
    editMaterialDetail(row){
      this.addDetailData.addDialogShowData = this.$options.data().addDetailData.addDialogShowData
      this.addDetailData.title = '修改物资明细'
      this.addDetailData.type = 2
      this.addDetailData.addDialogShowData.material_id = row.material_id
      this.addDetailData.addDialogShowData.id = row.id
      this.addDetailData.addDialogShowData.purchase_time = row.purchase_time*1000
      this.addDetailData.addDialogShowData.unit_price = row.unit_price
      this.addDetailData.addDialogShowData.num = row.num
      this.addDetailData.addDialogShowData.name = row.name
      this.addDetailData.isDialog = true
    },
    //删除物资明细
    delMaterialDetail(row){
      this.$confirm('确定要删除吗?', '信息', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        delMaterialDetail({id: row.id}).then((res)=>{
          this.$message({
            message: res.msg,
            type: 'success'
          });
          this.initLoad()
        })
      })
    },
    //物资明细提交
    addDetailSub(){
      let subData = {...this.addDetailData.addDialogShowData}

      //必填信息判断
      if (!subData.purchase_time || !subData.unit_price || !subData.num ){
        this.$confirm('*为必填项信息，请填写完整后提交！', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {})
        return false
      }

      subData.purchase_time = subData.purchase_time/1000
      if (this.addDetailData.type == 1){
        addMaterialDetail(subData).then((res)=>{
          this.addDetailData.isDialog = false;
          this.$message({
            message: res.msg,
            type: 'success'
          });
          this.initLoad()
        })
      } else {
        editMaterialDetail(subData).then((res)=>{
          this.addDetailData.isDialog = false;
          this.$message({
            message: res.msg,
            type: 'success'
          });
          this.initLoad()
        })
      }

    },
    //tabs 切换点击
    tabsClick(tab) {
      //this.tabsProp.active //tabs 当前的值
      this.tableProp.page.current_page = 1;
      this.initLoad()
      console.log(tab.name)
    },
    //tabs 点击搜索
    keyWordMethod() {
      this.tableProp.page.current_page = 1;
      this.initLoad()
      console.log(this.tabsProp.keywords);//搜索的值
    },
    //tabs 高级搜索点击确定
    highKeyWordMethod() {
      this.tableProp.page.current_page = 1;
      this.initLoad()
      console.log(this.tabsProp.heighKewWordField);//高级搜索的字段
    },

    //table 分页 点击当前页
    pageCurrentChange(val) {
      this.page.current_page = val
      this.initLoad();
    },
    handleSizeChange(val) {
      this.page.size = val
      this.initLoad();
    },
  },
};
</script>
<style lang="scss" scoped>
.content-tile {
  @include flex(nowrap, space-between, center);
  background: $title-bg;
  border: 1px solid $title-bg;
  padding: 8px;
  margin-bottom: 12px;

  .su-title {
    font-weight: bold;
    @include flex();

    i {
      font-size: 16px;
      color: $title-icon;
      font-weight: bold;
      margin-right: 8px;
    }
  }
}
.table-button {
  ul {
    @include flex(wrap, center);

    li {
      .el-button--small {
        margin: 5px;
      }
    }
  }
}
.pagination {
  position: fixed;
  bottom: 0;
  background: #fff;
  width: calc(100% - 248px);
  margin-left: -12px;
  padding: 12px;
  @include flex(nowrap, flex-end);
  z-index: 999;
  border-radius: 10px;
}
</style>
