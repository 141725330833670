<template>
  <div>
    <!-- title 组件 -->
    <common-title
        :titleProp="titleProp"

    /><!--tabs 组件 -->
    <common-tabs
        :tabsProp="tabsProp"
        @tabsClick="tabsClick"
        @keyWordMethod="keyWordMethod"
        @highKeyWordMethod="highKeyWordMethod"

    />
    <!--table 组件 -->
    <common-table
        :tableData="tableData"
        :tableProp="tableProp"
        @pageCurrentChange="pageCurrentChange"

    />
  </div>
</template>
<script>
// import {timer} from '@/assets/js/comment'
import {getLog} from '@/api/log_management/operation_log';

export default {
  components: {},
  props: [],
  data() {
    return {
      //title 数据源
      titleProp: {
        name: '操作日志',//标题名称
        butOperate: [{
          name: '导出',
          type: 'primary',
          method: 'addLog',
          id: 2000,
        }]
      },
      //tabs 数据源
      tabsProp: {
        active: '0',
        method: 'tabsClick', //点击tabs方法名
        keywords: '',//搜索的值
        keyWordMethod: 'keyWordMethod',// 搜索方法名
        keywordsShow: false,//搜索框是否显示
        isHighKeyWord: true,//是否有高级搜索
        highKeyWordDialogMethod: 'highKeyWordMethod',//高级搜索点击确定*/
        //高级搜索循环体
        highKeyWord: [{
          name: '时间范围',
          field: 'status',
          type: 5,
        }],
        //高级循环，下拉框等 内容名称 字段名+Options  接口读取

        //高级搜索的字段
        heighKewWordField: {status: ''},
        //tabs 标题
        tabsData: [{
          label: '全部',
          name: '0',
          total: 0,
        }]
      },
      //table 数据源
      tableProp: {
        index: false,//是否需要有序号
        selection: false,//是否是多选框
        selectionVal: 'id',// 开启多选后对比的字段
        selectionId: [],// 开启多选后，选择哪几条
        isPage: true, //是否分页
        statusName: 'status',//状态字段名 已启动未启动等状态按钮显示还是隐藏
        page: {
          current_page: 1,
          total: 0,
          size: 10,
          method: 'pageCurrentChange',
        },
        table: [{
          title: '用户',
          field: 'user_info',
          isWidth: false,
          width: '120px',
          headerAlign: 'center',
          align: 'center',
          tooltip: false,
          type: 3,
          name: 'name',
        }, {
          title: 'IP',
          field: 'ip',
          isWidth: false,
          width: '120px',
          headerAlign: 'center',
          align: 'center',
          tooltip: false,
          type: 0,
        },{
          title: '登陆电脑',
          field: 'computer',
          isWidth: false,
          width: '120px',
          headerAlign: 'center',
          align: 'center',
          tooltip: false,
          type: 0,
        }, {
          title: '功能模块',
          field: 'modular',
          isWidth: false,
          width: '120px',
          headerAlign: 'center',
          align: 'center',
          tooltip: false,
          type: 0,
        }, {
          title: '操作',
          field: 'operation',
          isWidth: false,
          width: '120px',
          headerAlign: 'center',
          align: 'center',
          tooltip: false,
          type: 0,
        }, {
          title: '操作摘要',
          field: 'remarks',
          isWidth: false,
          width: '120px',
          headerAlign: 'center',
          align: 'center',
          tooltip: false,
          type: 0,
        }, {
          title: '操作时间',
          field: 'updated_at',
          isWidth: false,
          width: '120px',
          headerAlign: 'center',
          align: 'center',
          tooltip: false,
          type: 9,
        }],
      },
      //表格数据
      tableData: [],
    };
  },
  created() {
    this.initLoad();
  },
  mounted() {
  },
  methods: {
    //表格初始化
    initLoad() {
      this.$store.state.loading = true;
      let params = {}

      if(this.tabsProp.heighKewWordField.status){
        params = {
          page: this.tableProp.page.current_page,
          start_time: this.tabsProp.heighKewWordField.status[0] / 1000,
          end_time: this.tabsProp.heighKewWordField.status[1] / 1000
        }
      } else {
        params = {
          page: this.tableProp.page.current_page,
        }
      }
      getLog(params).then((result) => {
        console.log(result)
        let res = result.data;

        this.tableProp.page.current_page = res.current_page;
        this.tableProp.page.total = res.total;
        this.tableProp.page.size = res.per_page;
        this.tableData = res.data
        for (let i = 0; i < this.tableData.length; i++) {
          if(this.tableData[i].user.computer){
            this.tableData[i].computer = this.tableData[i].user_info.name+"的电脑（"+ this.tableData[i].user.computer+")"
          }else {
            this.tableData[i].computer = this.tableData[i].user_info.name+"的电脑"
          }
        }
      })
    },
    //tabs 切换点击
    tabsClick(tab) {
      //this.tabsProp.active //tabs 当前的值
      this.tableProp.page.current_page = 1;
      this.initLoad()
      console.log(tab.name)
    },
    //tabs 点击搜索
    keyWordMethod() {
      this.tableProp.page.current_page = 1;
      this.initLoad()
      console.log(this.tabsProp.keywords);//搜索的值
    },
    //tabs 高级搜索点击确定
    highKeyWordMethod() {
      this.tableProp.page.current_page = 1;
      this.initLoad()
      console.log(this.tabsProp.heighKewWordField);//高级搜索的字段
    },

    //table 分页 点击当前页
    pageCurrentChange(val) {
      this.tableProp.page.current_page = val
      console.log('当前页:' + val);
      this.initLoad();
    },
  },
};
</script>
<style lang="scss" scoped></style>
