import http from '../http';

export function getMaterialAllList (params) {
    return http({
        url: "/api/ltd/materialAllList",
        method: "get",
        params:params
    })
}
export function getMaterialList (params) {
    return http({
        // url: "/api/ltd/materialPage",
        url: "/api/ltd/materialDetailPage",
        method: "get",
        params:params
    })
}
export function putMaterial (data) {
    return http({
        url: "/api/ltd/materialUser",
        method: "put",
        data:data
    })
}
export function editMaterial (data) {
    return http({
        url: "/api/ltd/material",
        method: "post",
        data:data
    })
}
export function deleteMaterial (data) {
    return http({
        url: "/api/ltd/material",
        method: "delete",
        data:data
    })
}
//创建物资明细
export function addMaterialDetail (data) {
    return http({
        url: "/api/ltd/materialDetail",
        method: "post",
        data:data
    })
}
//修改物资明细
export function editMaterialDetail (data) {
    return http({
        url: "/api/ltd/materialDetail",
        method: "put",
        data:data
    })
}
//删除物资明细
export function delMaterialDetail (data) {
    return http({
        url: "/api/ltd/materialDetail",
        method: "delete",
        data:data
    })
}